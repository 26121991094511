var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CFormGroup',_vm._b({scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]})]},proxy:true},{key:"input",fn:function(){return [_c('v-select',{class:_vm.inputClasses,attrs:{"label":"label","id":_vm.safeId,"reduce":function (option) { return option.value; },"options":_vm.options,"placeholder":"Select option"},on:{"input":function($event){return _vm.onSelect($event)}},model:{value:(_vm.cvalue),callback:function ($$v) {_vm.cvalue=$$v},expression:"cvalue"}})]},proxy:true},_vm._l((_vm.$options.slots),function(slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true)},'CFormGroup',{
    append: _vm.append,
    prepend: _vm.prepend,
    validFeedback: _vm.validFeedback,
    invalidFeedback: _vm.invalidFeedback,
    tooltipFeedback: _vm.tooltipFeedback,
    description: _vm.description,
    wrapperClasses: _vm.wrapperClasses,
    class: _vm.computedClasses,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }