<template>
  <dt :class="config.size_value">
    <CSpinner v-if="!options" color="secondary" size="sm" />
    <span v-else-if="!config.form.active || config.fconfig.readonly">
      {{ SelectedLabel }}
    </span>
    <CVSelect
      v-else-if="config.form.active && !config.fconfig.readonly"
      v-model="config.form.formData[config.fname]"
      :options="options"
      :is-valid="config.is_valid(config.fname)"
      :invalid-feedback="config.invalid_feedback(config.fname)"
      :description="config.fconfig.description"
    />
  </dt>
</template>

<script>
import CVSelect from "./CVSelect";

export default {
  props: ["config"],
  data() {
    return {
      options: null,
    };
  },
  components: {
    CVSelect,
  },
  created() {
    Promise.resolve(this.config.fconfig.options).then((opts) => {
      this.options = opts;
    });
  },
  watch: {
    "config.fconfig.options"() {
      //   console.log(this.config.fconfig.options);
      //  this.options = null
      Promise.resolve(this.config.fconfig.options).then((opts) => {
        this.options = opts;
      });
    },
  },
  computed: {
    SelectedLabel() {
      for (var i = 0; i < this.options.length; i++) {
        if (
          this.options[i].value == this.config.form.formData[this.config.fname]
        ) {
          return this.options[i].label;
        }
      }
      return this.config.form.formData[this.config.fname];
    },
  },
};
</script>
