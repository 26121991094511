<template>
  <CFormGroup
    v-bind="{
      append,
      prepend,
      validFeedback,
      invalidFeedback,
      tooltipFeedback,
      description,
      wrapperClasses,
      class: computedClasses,
    }"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>

    <template #input>
      <v-select
        label="label"
        :id="safeId"
        v-model="cvalue"
        :reduce="(option) => option.value"
        :options="options"
        placeholder="Select option"
        :class="inputClasses"
        @input="onSelect($event)"
      />
    </template>

    <template v-for="slot in $options.slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </CFormGroup>
</template>

<script>
import * as allFormMixins from "@coreui/vue/src/components/form/form-mixins";
const mixins = Object.values(allFormMixins);
import { selectProps as props } from "@coreui/vue/src/components/form/form-props";
import vSelect from "vue-select";

export default {
  name: "CVSelect",
  model: {
    prop: "value",
    event: "change",
  },
  inheritAttrs: false,
  components: { vSelect },
  slots: [
    "prepend",
    "prepend-content",
    "append-content",
    "append",
    "label-after-input",
    "valid-feedback",
    "invalid-feedback",
    "description",
  ],
  mixins,
  props,
  data() {
    return {
      cvalue: this.value || undefined,
    };
  },
  watch: {
    value: function (Nv) {
      // console.log("select watch", Nv, Ov)
      if (Nv != this.cvalue) {
        this.cvalue = Nv;
        // this.options = null
      }
    },
  },
  computed: {
    customSizeClass() {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `${this.custom ? "custom-select" : "form-control"}-${this.size}`;
      }
      return "";
    },
    inputClass() {
      const standardClass = `form-control${this.plaintext ? "-plaintext" : ""}`;
      return this.custom ? "custom-select" : standardClass;
    },
  },

  methods: {
    onSelect(e) {
      if (this.$attrs.multiple !== undefined) return;
      // console.log(this.cvalue)
      this.$emit("change", this.cvalue, e);
    },
  },
};
</script>

<style scoped>
.form-control {
  border-color: #fff;
  padding: 0;
  height: auto;
}
.form-control.is-invalid {
  border-color: rgb(229, 83, 83);
}
</style>
